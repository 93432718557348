import React, { useState, useEffect } from "react";
import Layout from "../../layout/default";
import {
  Row,
  Col,
  Card,
  Dropdown,
  Table,
  // Tooltip,
  // OverlayTrigger,
  // ListGroup,
  // Button,
} from "react-bootstrap";
import {
  Media,
  MediaGroup,
  MediaText,
  Icon,
  // ChartLabel,
  // ChartLegend,
  // CustomDropdownMenu,
  CustomDropdownToggle,
  OverlineTitle,
  // Timeline,
  // WorldMap,
  Image,
} from "../../components";
import { rootURL } from "../utilities/axios-config";
import CircularProgress from "@mui/material/CircularProgress";


function MusicChart() {
  const [loading, setLoading] = useState(false);
  const [topchart, settopchart] = useState([]);
  const [topalbums, settopalbums] = useState([]);
  const [topsingles, settopsingles] = useState([]);
  const [topradios, settopradios] = useState([]);
  const [topraggae, settopraggae] = useState([]);
  const [toprap, settoprap] = useState([]);
  const [topampiano, settopampiano] = useState([]);
  const [toprandb, settoprandb] = useState([]);
  const [topdancehall, settopdancehall] = useState([]);
  const [topafrobeat, settopafrobeat] = useState([]);
  const [topsocca, settopsocca] = useState([]);
  const [topsingle, settopsingle] = useState([]);

  const tableRecords = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/musicmatrics`;

      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            // console.log("topchats", row);

            settopchart((prevState) => {
              // Create a new array to avoid direct mutations
              const newData = row?.data?.topchats?.map((arr) => ({
                id: arr?.id,
                theme: "dark",
                icon: arr?.image,
                website: arr?.name,
                visitor: arr?.total_clicks,
              }));
              // Merge the new data with the previous state, ensuring no duplicates
              const combinedData = [...prevState, ...newData];

              // Use a Set to filter out duplicate entries by ID
              const uniqueData = Array.from(
                new Set(combinedData.map((a) => a.id))
              ).map((id) => combinedData.find((a) => a.id === id));

              return uniqueData;
            });

            

             // top rap
             settopsingles(row?.topsingles);
             settopradios(row?.topradios);
             settopalbums(row?.topalbums);
             settopraggae(row?.topraggae);
             settopampiano(row?.topampiano);
             settoprap(row?.toprap);
             settopsocca(row?.topsocca);
             settoprandb(row?.toprandb);
             settopdancehall(row?.topdancehall);
             settopafrobeat(row?.topafrobeat);
             settopsingle(row?.topsingle);

            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    tableRecords();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={50} thickness={8} />
          </div>
        ) : (
          <>
            <Layout title="Analytics">
              <Row className="g-gs">
                
                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Charts</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topchart.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                  <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>


                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Singles</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topsingle.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                  <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>


                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Mixes</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topsingles.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                   <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Albums</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topalbums.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                  <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Radio Stations</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topradios.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                   <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>



                 

                </Col>

                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Reggae</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topraggae.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                   <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>


                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Rap</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {toprap.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                   <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>



                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Ampiano</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topampiano.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                   <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>



                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Socca</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topsocca.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                   <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>



                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top AfroBeat</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topafrobeat.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                   <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>



                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top Dancehall</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topdancehall.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                   <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>



                <Col xxl="4">
                  <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Top RandB</h5>
                        </div>
                        <div className="card-tools">
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              as={CustomDropdownToggle}
                              className="btn btn-sm btn-icon btn-zoom me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                      <thead className="table-light table-head-sm">
                        <tr>
                          <th className="tb-col">
                            <OverlineTitle tag="span">source</OverlineTitle>
                          </th>
                          <th className="tb-col tb-col-end">
                            <OverlineTitle tag="span">viewers</OverlineTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {toprandb.map((item) => (
                          <tr key={item.id}>
                            <td className="tb-col">
                              <MediaGroup>
                                <Media
                                  className="flex-shrink-0"
                                  size="md"
                                  shape="circle"
                                  variant={item.theme}
                                >
                                   <Image src={item?.icon} staticImage />
                                </Media>
                                <MediaText>
                                  <span className="title">{item.website}</span>
                                </MediaText>
                              </MediaGroup>
                            </td>
                            <td className="tb-col tb-col-end">
                              <span className="small">{item.visitor}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Col>


              </Row>
            </Layout>
          </>
        )}
      </>
    </>
  );
}

export default MusicChart;
